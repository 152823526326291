import React from 'react';

import {AnimatePresence, motion, button} from 'framer-motion';

import DesktopLandingPageBackground from '../../assets/img/default background.png';
import gridFindECsLogo from '../../assets/img/gridFindECsLogo.png';
import doodle4google from '../../assets/img/doodle4google.png';
import nationalhistorybee from '../../assets/img/nationalhistorybee.png';
import modelunitednations from '../../assets/img/modelunitednations.png';
import MITLaunchXProgram from '../../assets/img/MITLaunchXProgram.png';
import laptoptransparent from '../../assets/img/laptoptransparent.png';
import {
    Box,
    Center,
    Button,
    VStack,
    Text,
    HStack,
} from "@chakra-ui/react";

export default function DesktopLandingPage({setShowIntro}) {

    return (
        <Box w={'100%'} h={'100%'}

             zIndex={'-1'} bgGradient={DesktopLandingPageBackground}
             pos={'fixed'}
             bgRepeat={'no-repeat'}
             bgSize={'cover'}
             bgPosition={'center'}
             overflow={'hidden'}
        >
            <Center height={'100%'}>
                <HStack spacing={0} height={'100%'} width={'100%'} display={'flex'} alignItems={'center'}>
                    <Box width={'60%'} ml={40}>
                        <VStack alignItems={'flex-start'} spacing={5}>
                            <img src={gridFindECsLogo} width={'475px'} mt={'10vh'}/>
                            <Text letterSpacing={4} fontWeight={800} color={'#360503'} font={"Poppins"}
                                  fontSize={'23.5px'}>
                                THE #1 DATABASE FOR ACCESSIBLE HIGH SCHOOL OPPORTUNITIES
                            </Text>
                            <Button fontSize={'20px'} _active={{
                                transform: 'scale(0.98)',
                                boxShadow: 'none',
                            }}

                                    _focus={{
                                        boxShadow: 'none',
                                    }}
                                    _hover={{
                                        bg: '#360503',
                                        transform: 'scale(1.1)',

                                        color: 'white',
                                    }

                                    } fontWeight={800} width={'225px'} height={'65px'} bg={'#360503'}
                                    color={'white'}
                                    borderRadius={'30px'}
                                    onClick={() => setShowIntro(false)}>

                                <HStack spacing={1} pl={2}>
                                    <Text letterSpacing={2}>
                                        GET STARTED

                                    </Text>
                                    <Box fontSize={'26px'} mt={1.5}>

                                        <ion-icon name="chevron-forward-outline"></ion-icon>
                                    </Box>
                                </HStack>


                            </Button>

                        </VStack>
                    </Box>
                    <Box bgGradient={laptoptransparent}
                         mt={'5%'}
                         ml={'47%'}
                         width={'60%'}
                         height={'80%'}
                         pos={'fixed'}
                         bgRepeat={'no-repeat'}
                         bgSize={'cover'}
                         bgPosition={'center'}
                         overflow={'hidden'}
                         zIndex={-1}
                    >
                    </Box>
                    <Center pos={'fixed'} ml={'55%'} width={'50%'}>
                        <Box width={'100%'} height={'500px'}>

                            <Box pos={'fixed'} opacity={0.5} ml={'5%'} pb={20}>
                                <img src={doodle4google} width={'245px'} height={'500px'}/>
                            </Box>
                            <Box pos={'fixed'} opacity={0.5} ml={'28%'} mt={20} opacity={0.5}>
                                <img src={MITLaunchXProgram} width={'255px'} height={'500px'}/>
                            </Box>
                            <Box pos={'fixed'} overflow={'hidden'} mt={60} ml={'25%'} opacity={0.5}>
                                <img src={nationalhistorybee} width={'235px'} height={'500px'}/>
                            </Box>
                            <Box pos={'fixed'} opacity={0.5} mr={'25%'} mt={150}>
                                <img src={modelunitednations} width={'285px'} height={'500px'}/>
                            </Box>
                        </Box>
                    </Center>
                </HStack>

            </Center>


        </Box>
    );

}
