import React, {useState} from 'react';
import ECModal from '../Modals/organizationModal.js';
import majorColors from '../findECs/majorColors.json';
import selectyourinterests from '../../assets/img/selectyourinterests.png';
import gridFindECsLogo from '../../assets/img/gridFindECsLogo.png';
import OrganizationCard, {DesktopCard, MobileCard} from '../Cards/organizationCard.js';
import {FeedbackCard} from '../Cards/feedbackCard.js';
import DiscordCard from '../Cards/DiscordCard.js';
import {
    Container,
    Grid,
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Image,
    Link,
    Spacer,
    Text,
    SimpleGrid,
    Stack,
    HStack,
    VStack,
    Badge,
    LinkBox,

    LinkOverlay,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    IconButton,
    Divide,
    useDisclosure
} from "@chakra-ui/react"
import {TriangleUpIcon, HamburgerIcon} from '@chakra-ui/icons'

export function Card({orgObj, bunnyIndex}) {

    const {isOpen, onOpen, onClose} = useDisclosure()

    let screenWidth = 0;
    let color = 0;
    let color2 = 0;
    let color3 = 0;
    if (orgObj.type === "org") {
        if (!/^https?:\/\//i.test(orgObj.website)) {
            orgObj.website = 'https://' + orgObj.website;
        }
        screenWidth = 100.0 / 5 * (parseInt(orgObj.size) + parseInt(orgObj.impact) + parseInt(orgObj.momentum) - 4) + 35 + "%";

        color = majorColors.filter(function (x) {
            return x.name == orgObj.major;
        })[0].color;
        color2 = majorColors.filter(function (x) {
            return x.name == orgObj.major;
        })[0].color2;
        color3 = majorColors.filter(function (x) {
            return x.name == orgObj.major;
        })[0].color3;
    }

    return (
        <Box>
            {orgObj.type === "discord" ?
                <>
                    <Box width={'100%'} display={"inline-block"}>
                        <DiscordCard orgObj={orgObj}/>
                    </Box>
                </>
                :
                <>
                    <Box width={'100%'} display={["none", "none", "inline-block", "inline-block"]}>
                        <OrganizationCard device={'desktop'} orgObj={orgObj} screenWidth={screenWidth} color={color}
                                          color2={color2} bunnyIndex={bunnyIndex}/>
                    </Box>
                    <Box width={'100%'} display={["inline-block", "inline-block", "none", "none"]}>
                        <OrganizationCard device={'mobile'} orgObj={orgObj} screenWidth={screenWidth} color={color}
                                          color2={color2} bunnyIndex={bunnyIndex}/>
                    </Box>
                </>
            }
        </Box>
    );
}










