import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Box,
    VStack,
    HStack,
    Center,
    useDisclosure
} from "@chakra-ui/react"

import "@fontsource/poppins/800.css";
import "@fontsource/poppins/600.css";
import majorColors from '../findECs/majorColors.json';


function organizationModal({orgObj, isOpen, onClose}) {
    let color = majorColors.filter(function (x) {
        return x.name == orgObj.major;
    })[0].color;
    let color2 = majorColors.filter(function (x) {
        return x.name == orgObj.major;
    })[0].color2;
    let color3 = majorColors.filter(function (x) {
        return x.name == orgObj.major;
    })[0].color3;


    let size = parseInt(orgObj.amount_of_competitors.replace(/,/g, ''));
    let sizeStr = size >= 1000000 ? (size / 1000000).toFixed(1) + 'M' : size >= 1000 ? ((size / 1000)) + "K" : size.toString();

    return (
        <Modal isCentered size={["xs", "md", "md"]} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent css={{
                "WebkitTouchCallout": "none",
                "WebkitUserSelect": "none",
                "MozUserSelect": "none",
                "msUserSelect": "none"
            }}>
                <ModalHeader fontFamily={"Poppins"}>

                    <Text color={'#360503ff'} fontSize={'28px'} w={'95%'} fontWeight={'800'}>
                        {orgObj.competition}
                    </Text>
                    <Text fontSize={'16px'} color={color3} fontWeight={'700'}>
                        {orgObj.major.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) + " Competition"}
                    </Text>
                    <HStack paddingTop={3} fontFamily={"Poppins"} fontWeight={600} fontSize={'8px'} color='white'
                            display={'flex'}
                            justifyContent={'space-between'}>
                        <Box display={'flex'} justifyContent={'center'}
                             bg={color2}
                             borderRadius={4}
                             h={'30px'}
                             color={color3}
                             width={'100%'}>
                            <Center>
                                <Text fontSize={'12px'}>  {orgObj.cost === "Yes" ?
                                    <HStack>
                                        <Box pt={2} fontSize={'22px'}>
                                            <ion-icon name="thumbs-down"></ion-icon>
                                        </Box>
                                        <Text>
                                            Not Free
                                        </Text>
                                    </HStack> : <HStack>
                                        <Box pt={1} fontSize={'22px'}>
                                            <ion-icon name="thumbs-up"></ion-icon>
                                        </Box>
                                        <Text>
                                            Free
                                        </Text>
                                    </HStack>}
                                </Text>
                            </Center>
                        </Box>
                        <Box display={'flex'} justifyContent={'center'}
                             bg={color2}
                             borderRadius={4}
                             h={'30px'}
                             color={color3}
                             width={'100%'}>
                            <Center>
                                <Text fontSize={'12px'}>
                                    {orgObj.competitor_type !== "Teams" ? <HStack spacing={0}>
                                        <Box pt={2} fontSize={'24px'}>
                                            <ion-icon name="man"></ion-icon>
                                        </Box>
                                        <Text>
                                            Solo!
                                        </Text>
                                    </HStack> : <HStack>
                                        <Box pt={1} fontSize={'20px'}>
                                            <ion-icon name="people"></ion-icon>
                                        </Box>
                                        <Text>
                                            Teams!
                                        </Text>
                                    </HStack>}
                                </Text>
                            </Center>
                        </Box>
                        <Box display={'flex'} justifyContent={'center'}
                             color={color3}
                             bg={color2} borderRadius={4}
                             h={'30px'}
                             width={'100%'}>
                            <Center>
                                <HStack>
                                    <Box pt={1} fontSize={'20px'}>
                                        <ion-icon name="person"></ion-icon>
                                    </Box>
                                    <Text fontSize={'12px'}>{size}</Text>

                                </HStack>
                            </Center>
                        </Box>
                    </HStack>
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>

                    <Text overflowY={'auto'} color={'#360503ff'} h={'200px'} mt={'-10px'}
                          fontSize={'13px'}>{orgObj.long_description}</Text>
                    <Text fontSize={'16px'} color={color3} fontWeight={'600'}>
                        Skills
                    </Text>
                    <Text overflowY={'auto'} fontWeight={500} color={'#360503ff'} h={'50px'}
                          fontSize={'13px'}>{orgObj.skills}</Text>
                    <Center fontFamily={"Poppins"} padding={''}>
                        <Box display={'flex'} justifyContent={'center'} borderRadius='5px' width="100%" h='50px'
                             mb={'20px'} mt={'10px'}
                             bg={color3} color={'white'}
                             mr={3} as='a'
                             href={orgObj.website}
                             target='_blank'
                             fontSize={'16px'}>

                            <Center fontWeight={500} textAlign={'center'}>
                                <Text>
                                    Website
                                </Text>
                                <Box pl={2} pt={"4px"} fontSize={'20px'}>

                                    <ion-icon name="open-outline"></ion-icon>
                                </Box>
                            </Center>
                        </Box>
                    </Center>
                </ModalBody>

            </ModalContent>
        </Modal>
    )
}

export default organizationModal 