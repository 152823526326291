import React, {useEffect, useState} from 'react';
import {
    useDisclosure,
    Box,
    Button,
    Center,
    Container,
    Flex,
    HStack,
    Heading,
    Image,
    Link,
    Spacer,
    Text,
    VStack,
    SimpleGrid,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from "@chakra-ui/react";

// a

export function Footer() {
    return (
        <Container w={'8em'} as='footer'>
            <Center>
                <Box mt={'40px'} mb={'20px'} fontSize={'14px'} pr={2}>
                    <VStack spacing={0}>
                        <HStack pr={2}>

                            {/* <Text as={'b'}>Socials </Text> */}
                            <Link
                                href='https://forms.gle/gwB889QFW5qgnaZKA'
                                target='_blank'>
                                <Box fontSize={'24px'} pt={0.5}>
                                    <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                                </Box>
                            </Link>

                            <Link
                                href='https://www.instagram.com/find_ecs/'
                                target='_blank'>
                                <Box fontSize={'22px'}>
                                    <ion-icon name="logo-instagram"></ion-icon>
                                </Box>
                            </Link>

                        </HStack>

                        <Link
                            href='https://www.trajectory.education/'
                            target='_blank' mb={'2px'}>
                            <HStack spacing={1}>

                                <Text> Made with</Text>
                                <Box fontSize={'16px'} pt={1}>
                                    <ion-icon name="heart-outline"></ion-icon>
                                </Box>
                                <Text> by Trajectory</Text>
                            </HStack>
                        </Link>

                    </VStack>
                </Box>
            </Center>
        </Container>
    );
}

export default Footer;