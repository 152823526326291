import React, {useState} from 'react';
import ECModal from '../Modals/organizationModal.js';
import {
    Container,
    Grid,
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Image,
    Link,
    Spacer,
    Text,
    SimpleGrid,
    Stack,
    HStack,
    VStack,
    Badge,
    LinkBox,

    LinkOverlay,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    IconButton,
    Divide,
    useDisclosure
} from "@chakra-ui/react"

//bunnies!
import arrowBunny from '../../assets/img/bunnies/arrowBunny.png';
import balloonBunny from '../../assets/img/bunnies/balloonBunny.png';
import bobaBunny from '../../assets/img/bunnies/bobaBunny.png';
import crushedToDeathByAComicallyLargeClipartHeartBunny from '../../assets/img/bunnies/crushedToDeathByAComicallyLargeClipartHeartBunny.png';
import flowerBunny from '../../assets/img/bunnies/flowerBunny.png';
import heartBunny from '../../assets/img/bunnies/heartBunny.png';
import heartHandsBunny from '../../assets/img/bunnies/heartHandsBunny.png';
import sleepyBunny from '../../assets/img/bunnies/sleepyBunny.png';
import sparkleBunny from '../../assets/img/bunnies/sparkleBunny.png';


function OrganizationCard({device, orgObj, screenWidth, color, color2, color3, bunnyIndex}) {

    const bunnyArr = [arrowBunny, balloonBunny, bobaBunny, crushedToDeathByAComicallyLargeClipartHeartBunny, flowerBunny, heartBunny, heartHandsBunny, sleepyBunny, sparkleBunny];
    const widthArr = ['40px', '35px', '30px', '42px', '30px', '40px', '40px', '38px', '45px']

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [touching, setTouching] = useState(false)

    return (
        <Center>
            <Box>
                <LinkBox
                    cursor={'pointer'}
                    bg={'white'} color={'#360503ff'} borderRadius={'lg'} borderWidth={'1px'} 
                    overflow={'hidden'}
                    w={'8em'} p={'0px'}
                    onClick={onOpen}
                    mt={'2px'}
                    mb={'-30px'}

                    _hover={device==='desktop' ? { 
                        transform: 'scale(1.05)',
                        boxShadow: '0px 0px 10px #360503ff' 
                    }: {}}
                    onTouchStart={device==='mobile' ? (() => setTouching(true)) : undefined}
                    onTouchEnd={device==='mobile' ? (() => setTouching(false)) : undefined}
                    transform={device==='mobile' ? (touching ? 'scale(1.05)' : 'scale(1)') : {}}
                    boxShadow={device==='mobile' ? (touching ? '0px 0px 10px #360503ff' : '') : ('md')}

                    style={{ // makes card not only grow but shrink w/ transition
                        transition: 'all 0.2s ease-in-out',
                    }}
                >

                    <ECModal orgObj={orgObj} isOpen={isOpen} onClose={onClose}/>

                    <Flex m={'2px'} direction={'column'} pt={'8px'} pb={'8px'} pl={'12px'} pr={'12px'} css={{
                        "WebkitTouchCallout": "none",
                        "WebkitUserSelect": "none",
                        "MozUserSelect": "none",
                        "msUserSelect": "none"
                    }}>

                        <Flex direction={"row"} pb={'2px'}>
                            <Text fontSize={'16px'} fontWeight={'bold'} lineHeight={'160%'}>
                                {orgObj.competition} {/* THIS IS THE NAME OF THE ORG */}
                            </Text>
                            <Spacer/>
                            <Badge mr={'3px'} bg={color2} mt={'1.5px'} pt={'1.2px'} h={'1.6em'}
                                   fontSize={'9px'}
                                   color={color3}> {orgObj.major} </Badge> {/* badges will be added from db */}
                        </Flex>

                        <Box w={'6.2em'} position={'relative'}>
                            <Box>
                                <Text fontSize={'11px'} fontStyle={'italic'}>
                                    {orgObj.short_description}
                                </Text>
                            </Box> {/* The desc of org ( 1 line) */}
                            <Spacer/>
                            <Box h={'8px'} bg={'rgba(110,110,110,0.18)'} mt={'8px'} borderRadius={'12px'}>
                                <Container w={'100%'} h={'8px'} bg={color} m={'0px'} borderRadius={'12px'}/>
                            </Box> 
                        </Box>
                        <Box position={'absolute'} right={device==='device'?'8px':'15px'} bottom={'0px'}>
                            <img src={bunnyArr[bunnyIndex]} width={widthArr[bunnyIndex]}/>
                        </Box>
                    </Flex>
                </LinkBox>
            </Box>
        </Center>
    );
}

export default OrganizationCard;