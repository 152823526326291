import React, {useEffect, useState} from 'react';

import FindECsLandingParent from '../components/findECs/FindECsLandingParent.js';
import DesktopLandingPageBackground from '../assets/img/default background.png';
import {AnimatePresence} from 'framer-motion';
import {
    Box,
} from "@chakra-ui/react";

import {Renderer, RenderGridStep} from '../components/Renderer.js';
import banner from '../assets/img/trajectoryBanner.png'

function Home() {


    const [showIntro, setShowIntro] = useState(true);


    return (

        <Box fontSize={'40px'}>

            <Box w={'120vw'} h={'120vh'} bgGradient={DesktopLandingPageBackground}
                 bgRepeat={'no-repeat'}
                 bgSize={'cover'}
                 bgPosition={'center'}
                 overflow={'hidden'}
                 pos={'fixed'}
                 zIndex={'-1'}></Box>

            {showIntro ? <FindECsLandingParent setShowIntro={setShowIntro}/> : <Renderer columns={1}/>}

            {/* <Footer/>*/}
        </Box>

    );
}


export default Home;