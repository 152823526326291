import React, {useState} from 'react';
import ECModal from '../Modals/organizationModal.js';
import megaphone from '../../assets/img/iconmonstr-megaphone-4.svg';
import {
    Container,
    Grid,
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Image,
    Link,
    Spacer,
    Text,
    SimpleGrid,
    Stack,
    HStack,
    VStack,
    Badge,
    LinkBox,

    LinkOverlay,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    IconButton,
    Divide,
    useDisclosure
} from "@chakra-ui/react"


export default function DiscordCard({orgObj, screenWidth}) {
    const textColor = 'white';
    const bg = 'brandBrown.100';

    return (
        <Center>
            <Box>
                <LinkBox
                    cursor={'pointer'}
                    bg={'white'} color={'#360503ff'} borderRadius={'lg'} boxShadow={'md'}
                    overflow={'hidden'}
                    w={'8em'} p={'0px'}
                    h={'2.5em'}
                    borderColor={'#738ADB'}
                    borderWidth={'2px'}


                    mt={'2px'}
                    mb={'-30px'}
                    boxShadow={'0px 0px 8px #738ADB'}
                    _hover={{
                        transform: 'scale(1.05)',
                        boxShadow: '0px 0px 10px #360503ff'
                    }}
                    style={{ // makes card not only grow but shrink w/ transition
                        transition: 'all 0.2s ease-in-out',
                    }}
                >

                    <Flex userSelect={"none"} m={'2px'} direction={'column'} pt={'8px'} pl={'12px'}
                          pr={'12px'}>

                        <Flex direction={"row"}>
                            <Text fontSize={'18px'} color={'#738ADB'} fontWeight={'700'} lineHeight={'180%'}>
                                Discord
                            </Text>
                            <Spacer/>
                            <Box fontSize={'24px'} color={'#738ADB'} mb={0}>
                                <ion-icon name="logo-discord"></ion-icon>
                            </Box>

                        </Flex>


                        <Box>
                            <Text userSelect={"none"} fontSize={'12px'} fontWeight={500} fontStyle={'italic'}>
                                Want to chat with other like-minded students? Need to find a team for an EC? Join our
                                Discord!
                            </Text>
                            <LinkOverlay
                                href='https://discord.gg/zngBD3xjkt'
                                target='_blank'></LinkOverlay>
                        </Box>
                    </Flex>
                </LinkBox>
            </Box>
        </Center>
    );
}